<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="labelTitle" footer-class="footerClass"
      no-close-on-backdrop  content-class="shadow staff-utilization-modal"
      @hidden="hidden"
      scrollable>

      <b-alert variant="danger" dismissible v-model="state.errorShow">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ $t('error.attention_required') }} 
      </b-alert>

      <b-form-group :label="$t('staff.field.staff')" label-for="staffname">
        <b-input-group>
          <b-form-input type="text" :placeholder="$t('staff.select_staff')" readonly class="form-label"
              v-on="canList() ? { click: staffSelectorOpen } : {}"
              v-model="staffName"
              :data-vv-as="$t('staff.field.staff')"
              data-vv-name="staffName"
              data-vv-delay="500"
              v-validate="{ required: true }"/> 
          <b-input-group-append v-if='canList()'>
            <b-button @click="staffSelectorOpen"><span>{{ $t('button.select') }}</span></b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showStaffNameError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staffName') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-row>
        <b-col v-for="(field, index) in customFieldsFiltered" :key="index" cols="12">
          <b-form-group>
            <template v-if="field.type !== 'Boolean'" slot="label">
              <span class="mr-2">{{ field.displayName }}</span>
              <span v-if="field.description">
                <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                  {{ field.description }}
                </b-popover>  
              </span>
            </template>
            <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field"></CustomField>
          </b-form-group>
        </b-col>
      </b-row>
             
      <b-form-group v-if="isGenericStaff && supportsUnit" :label="$t('staff.field.unit')" label-for="staffUnit">
        <b-input-group>
          <b-input-group-prepend>
            <b-button id="UNIT_SUBTRACT" @click.prevent="unitAddMinus(-1)">
              <font-awesome-icon :icon="['far', 'minus']"/>
              <b-popover
                target="UNIT_SUBTRACT"
                placement="top"
                triggers="hover"
                :content="$t('staff.button.unit_subtract')">
              </b-popover>
            </b-button>
          </b-input-group-prepend>
          <b-form-input type="text" class="form-label"
              v-model="staffUnit"/>
          <b-input-group-append>
            <b-button id="UNIT_ADD" @click.prevent="unitAddMinus(1)">
              <font-awesome-icon :icon="['far', 'plus']"/>
              <b-popover
                target="UNIT_ADD"
                placement="top"
                triggers="hover"
                :content="$t('staff.button.unit_add')">
              </b-popover>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showStaffUnitError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staffUnit') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group :label="$t('staff.field.utilization')" :label-for="`staffUtilization_${id}`">
        <b-input-group>
          <b-input-group-prepend>
            <b-button @click.prevent="utilizationAddMinus(-0.1)">
              <font-awesome-icon :icon="['far', 'minus']"/>
            </b-button>
          </b-input-group-prepend>
          <b-form-input :id="`staff-utilization_${id}`" v-model="staffUtilization" type="text" @blur="utilizationFormat"/>
          <b-input-group-append>
            <b-button @click.prevent="utilizationAddMinus(0.1)">
              <font-awesome-icon :icon="['far', 'plus']"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showStaffUtilizationError }">
          <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('staffUtilization') }}
        </b-form-invalid-feedback>
      </b-form-group>

      <template v-slot:modal-footer="{}">
        <b-form-checkbox v-if="canApplyAll" class="apply-to-all" v-model="applyToAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok">{{ $t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- staff selector -->
    <StaffSelectorModalForAdmin v-if="state.staffSelectorShow"
      :show.sync="state.staffSelectorShow" 
      nonAdmin
      singleSelection
      @ok="staffSelectorOk"
    />
  </div>
</template>

<script>
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';

export default {
  name: 'StaffUtilizationModal',
  components: {
    StaffSelectorModalForAdmin: () => import('@/components/modal/StaffSelectorModalForAdmin'),
    CustomField: () => import('@/components/CustomField.vue')
  },
  props: {
    uuId: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    unit: {
      type: Number,
      default: 1
    },
    utilization: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean,
      required: true
    },
    genericStaff: {
      type: Boolean,
      default: false
    },    
    title: {
      type: String,
      default: null
    },
    canApplyAll: {
      type: Boolean,
      default: false
    },
    edgeName: {
      type: String,
      default: null
    },
    cData: {
      type: Object,
      default: () => { return {} }
    },
    supportsUnit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      permissionName: "STAFF",
      state: {
        modalShow: false,
        errorShow: false,
        staffSelectorShow: false
      },
      id: null,
      staffName: null,
      staffUnit: null,
      staffUtilization: null,
      isGenericStaff: false,
      oldId: null,
      applyToAll: false,
      
      customFields: [],
      customData: {}
    }
  },
  created() {
    this.onCreated();
  },
  beforeDestroy() {
    delete this.genericStaff;
  },
  watch: {
    show(newValue) {
      this.processWhenShowModal(newValue);
    },
    uuId(newValue) {
      this.updateUuId(newValue);
    },
    name(newValue) {
      this.updateName(newValue);
    },
    utilization(newValue) {
      this.updateUtilization(newValue);
    },
    unit(newValue) {
      this.updateUnit(newValue);
    },
    genericStaff(newValue) {
      this.updateGenericStaff(newValue);
    },
    cData(newValue) {
      this.customData = { ...newValue };
    }
  },
  computed: {
    componentId() {
      return `STAFF_UTILIZATION_FORM_${this.id}`;
    },
    customFieldsFiltered() {
      return this.customFields;
    },
    showStaffNameError() {
      return fieldValidateUtil.hasError(this.errors, 'staffName');
    },
    showStaffUtilizationError() {
      return fieldValidateUtil.hasError(this.errors, 'staffUtilization');
    },
    showStaffUnitError() {
      return fieldValidateUtil.hasError(this.errors, 'staffUnit');
    },
    labelTitle() {
      return this.title? this.title : this.$t('staff.title_utilization');
    }
  },
  methods: {
    async onCreated() {
      await getCustomFieldInfo(this, 'RESOURCE_LINK', this.edgeName);
      this.updateShow(this.show);
      this.updateUuId(this.uuId);
      this.updateName(this.name);
      this.updateUtilization(this.utilization);
      this.updateUnit(this.unit);
      this.updateGenericStaff(this.genericStaff);
      this.customData = { ...this.cData };
    },
    async processWhenShowModal(newValue) {
      if (newValue) {
        await getCustomFieldInfo(this, 'RESOURCE_LINK', this.edgeName);
        this.$validator.resume();
      }
      this.updateShow(newValue);
      delete this.genericStaff; //Clean up genericStaff property. It will be created when user selects a new staff in staff field.
      this.applyToAll = false;
    },
    updateShow(newValue) {
      this.state.modalShow = newValue;
    },
    updateUuId(newValue) {
      this.id = newValue;
    },
    updateName(newValue) {
      this.staffName = newValue;
    },
    updateUtilization(newValue) {
      this.staffUtilization = typeof newValue !== 'undefined' && newValue !== null ? newValue * 100 : 100;
    },
    updateUnit(newValue) {
      this.staffUnit = newValue? newValue : 1;
    },
    updateGenericStaff(newValue) {
      this.isGenericStaff = newValue; 
    },
    ok() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.customData[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.$validator.validate().then(valid => {
        if (valid) {
          this.state.errorShow = false;
          const unit = this.isGenericStaff? parseInt(this.staffUnit) : 1;
          const payload = { 
            id: this.id
            , name: this.staffName
            , utilization: this.staffUtilization / 100
            , unit: unit < 1? 1 : unit
            , genericStaff: this.isGenericStaff
            , oldId: this.uuId
            , applyToAll: this.applyToAll
            , customFields: this.customFields
            , customData: this.customData
          };

          this.$emit('ok', payload);
          this.$emit('update:show', false);
        } else {
          this.state.errorShow = true;
        }
      });
    },
    cancel() {
      this.$emit('cancel');
      this.$emit('update:show', false);
    },
    hidden() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    staffSelectorOpen() {
      this.state.staffSelectorShow = true;
    },
    staffSelectorOk({ details }) {
      const selected = details[0];
      this.id = selected.uuId;
      this.staffName = selected.name;
      this.isGenericStaff = selected.genericStaff;
    },
    unitAddMinus(delta) {
      if(isNaN(this.staffUnit)) {
        this.staffUnit = 1;
      }
      if (parseInt(this.staffUnit) <= 1 && delta === -1) {
        return;
      }
      const value = parseInt(this.staffUnit);
      this.staffUnit = value + delta;
    },
    utilizationAddMinus(delta) {
      if(isNaN(this.staffUtilization)) {
        this.staffUtilization = 1;
      }
      const value = parseInt(this.staffUtilization);
      this.staffUtilization = value + delta;
      // round to nearest 10
      if (this.staffUtilization % 10 !== 0) {
        if (delta > 0) {
          this.staffUtilization = Math.ceil(this.staffUtilization / 10) * 10;
        }
        else {
          this.staffUtilization = Math.floor(this.staffUtilization / 10) * 10;
        }
      }
      this.utilizationFormat();
    },
    utilizationFormat() {
      if(this.staffUtilization == null || isNaN(this.staffUtilization) || (typeof this.staffUtilization == 'string' && this.staffUtilization.trim().length == 0)) {
        this.staffUtilization = 1;
      }
      const value = parseInt(this.staffUtilization);
      if(value < 0) {
        this.staffUtilization = 0;
      } else if(value > 100) {
        this.staffUtilization = 100;
      } else {
        this.staffUtilization = value;
      }
    },
  }

}
</script>

<style lang="scss">
.staff-utilization-modal .apply-to-all {
  position: absolute;
  left: 15px;
}
</style>